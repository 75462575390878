import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import Church from './components/Church';
import ChurchList from './components/ChurchList';
import ChurchRegistration from './components/ChurchRegistration';
import SignIn from './components/SignIn';
import ResetPassword from './components/ResetPassword';
import ConfirmPassword from './components/ConfirmPassword';
import TrainingResources from './components/TrainingResources';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact component={SignIn} path="/signin" />
        <Route exact component={ResetPassword} path="/resetpassword" />
        <Route exact component={ConfirmPassword} path="/confirmpassword" />
        <Route exact component={ChurchRegistration} path="/churchregistration" />
        <PrivateRoute exact component={ChurchList} path="/churchlist" />
        <PrivateRoute exact component={Church} path="/church" />
        <PrivateRoute exact component={Church} path="/church/:id" />
        <PrivateRoute exact component={TrainingResources} path="/trainingresources" />
        <PrivateRoute exact component={TrainingResources} path="/" />
      </Switch>
    </Router>
  );
}

export default App;
